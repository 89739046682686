import React, { useState, useEffect } from "react";
import "./Mapping.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
import { BsGear, BsQuestionSquare } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import { LuBookDown, LuContact2 } from "react-icons/lu";
import { LuFileQuestion } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import DropFileInput from "./DropFileInput.js";
import Spinner from "./SpinnerDs.js";
import Popup from "./PopUp.js";
import Dropdown from "react-dropdown";
import axios from "axios";
import { MdSpatialAudio } from "react-icons/md";

const Mapping = (props) => {
  // RESET ALL TOKEN
  useEffect(() => {
    props.reset();
  }, []);

  const MainPageMapping = () => {
    const [hasilVis, setHasilVis] = useState(true);
    const [loadingVis, setLoadingVis] = useState(false);
    // const [spinner, setSpinner] = useState(false);

    // Inisiasi Interval 3 detik
    const [start, setStart] = useState(false);

    // Loading Spinner, Error, dan Proses upload
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [prosesUpload, setProsesUpload] = useState("0");

    const [data, setData] = useState([
      { no: 1, kompetensi: "-", level: "-", score: "-" },
    ]);

    const [tokenMapping, setTokenMapping] = useState("");
    const [statusMapping, setStatusMapping] = useState("");

    // File
    const [files, setFiles] = useState("");
    const options_jenisInputan = ["Ebook (PDF)", `Modul (PPTX)`];
    const [jenisInputan, setJenisInputan] = useState(options_jenisInputan[0]);
    const [namaFile, setNamaFile] = useState("");

    const mappingEbook = async (formData, maxAttempts) => {
      let statusStore = "";
      let attempts = 0;

      const mapps = async () => {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/new-mapping-ebook/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 0, // Timeout diatur ke 0 untuk menunggu sampai selesai
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(`Progress: ${percentCompleted}%`);
                if (percentCompleted < 100) {
                  setLoadingSpinner(true);
                  setProsesUpload(percentCompleted);
                }
                // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
              },
            }
          );

          if (
            response.data.your_request_token &&
            response.data.your_request_token.length > 0
          ) {
            setLoadingSpinner(false);
            setLoadingVis(true);

            const data = response.data.your_request_token;
            const status = response.data.status;

            statusStore = data;

            // console.log("TOKEN MAPPING NYA:", data);
            console.log("STATUS MAPPING NYA:", status);

            setTokenMapping(data);
            setStatusMapping(status);

            return true; // Selesai dengan sukses
          } else {
            console.log("Gagal Mapping, mencoba lagi...");
            return false; // Gagal, akan mencoba lagi
          }
        } catch (error) {
          console.error("Error message:", error.message);

          if (error.response) {
            console.error("Server responded with:", error.response.status);
            setErrorMsg("Server responded with:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
            setErrorMsg("No response received:", error.request);
          } else {
            console.error("Axios config error:", error.message);
            setErrorMsg("Axios config error:", error.message);
          }

          return false; // Error, akan mencoba lagi
        }
      };

      while (statusStore === "" && attempts < maxAttempts) {
        attempts += 1;
        const isSuccessful = await mapps();

        if (isSuccessful) {
          setStart(true);
          break;
        } else if (attempts < maxAttempts) {
          console.log(
            `Menunggu 5 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
          );
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }

      if (statusStore === "") {
        console.log(`Gagal Mapping Ebook setelah ${maxAttempts} percobaan.`);
      }

      return statusStore;
    };

    const mappingModule = async (formData, maxAttempts) => {
      let statusStore = "";
      let attempts = 0;

      const mapps = async () => {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/new-mapping-module/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 0, // Timeout diatur ke 0 untuk menunggu sampai selesai
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(`Progress: ${percentCompleted}%`);
                if (percentCompleted < 100) {
                  setLoadingSpinner(true);
                  setProsesUpload(percentCompleted);
                }
                // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
              },
            }
          );

          if (
            response.data.your_request_token &&
            response.data.your_request_token.length > 0
          ) {
            setLoadingSpinner(false);
            setLoadingVis(true);

            const data = response.data.your_request_token;
            const status = response.data.status;

            statusStore = data;

            // console.log("TOKEN MAPPING NYA:", data);
            console.log("STATUS MAPPING NYA:", status);

            setTokenMapping(data);
            setStatusMapping(status);

            return true; // Selesai dengan sukses
          } else {
            console.log("Gagal Mapping, mencoba lagi...");
            return false; // Gagal, akan mencoba lagi
          }
        } catch (error) {
          console.error("Error message:", error.message);

          if (error.response) {
            console.error("Server responded with:", error.response.status);
            setErrorMsg("Server responded with:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
            setErrorMsg("No response received:", error.request);
          } else {
            console.error("Axios config error:", error.message);
            setErrorMsg("Axios config error:", error.message);
          }

          return false; // Error, akan mencoba lagi
        }
      };

      while (statusStore === "" && attempts < maxAttempts) {
        attempts += 1;
        const isSuccessful = await mapps();

        if (isSuccessful) {
          setStart(true);
          break;
        } else if (attempts < maxAttempts) {
          console.log(
            `Menunggu 5 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
          );
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }

      if (statusStore === "") {
        console.log(`Gagal Mapping Ebook setelah ${maxAttempts} percobaan.`);
      }

      return statusStore;
    };

    // Ambil Status Generate per interval 3 detik (KHUSUS REVIEW BUTTON

    const fetchData = async () => {
      const formData = new FormData();
      formData.append("request_token", tokenMapping);

      // console.log("Token Mapping : ", tokenMapping);
      try {
        if (jenisInputan === "Ebook (PDF)") {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + "/result-mapping-ebook/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
            }
          );
          let status = res.data.status;
          let data = res.data.competency_mapping;

          console.log("Status : ", status);
          console.log("Data : ", data);

          const newData = data.map((item, index) => ({
            no: index + 1, // Incremental id starting from 1
            kompetensi: item.competency,
            level: item.level,
            score: item.similarity,
          }));

          if (newData.length != 0) {
            setData(newData);
          }
          if (status === "done") {
            setStatusMapping(status);
            console.log("Proses Selesai");
            setStart(false);
          }
        } else if (jenisInputan === "Modul (PPTX)") {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + "/result-mapping-module/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
            }
          );
          let status = res.data.status;
          let data = res.data.competency_mapping;

          console.log("Status : ", status);
          console.log("Data : ", data);

          const newData = data.map((item, index) => ({
            no: index + 1, // Incremental id starting from 1
            kompetensi: item.competency,
            level: item.level,
            score: item.similarity,
          }));

          if (newData.length != 0) {
            setData(newData);
          }

          if (status === "done") {
            setStatusMapping(status);
            console.log("Proses Selesai");
            setStart(false);
          }
        }
      } catch (error) {
        console.error("Error saat memanggil API:", error);
      }
    };

    useEffect(() => {
      let interval;
      console.log("TERPANGGIL");

      if (start) {
        interval = setInterval(async () => {
          await fetchData(); // Call the fetchData function here
        }, 3000);
      }

      return () => clearInterval(interval);
    }, [start, tokenMapping]);

    useEffect(() => {
      console.log("Settings Loading dengan Status");
      console.log("+" + statusMapping + "+");
      // console.log("Token Req : ", tokenMapping);

      if (statusMapping === "done") {
        console.log("DONEEEEEEEEEEEEEEEEEEEEEE");
        setHasilVis(true);
        setLoadingVis(false);

        setTokenMapping("");
        setStatusMapping("");
      }
      // Jika selain "Done"
      else if (statusMapping === "processing") {
        console.log("PROCESINGGGGGGGGGGGGGG");
        setHasilVis(false);
        setLoadingVis(true);
        setLoadingSpinner(false);
      } else if (statusMapping === "waiting") {
        console.log("WAITINGGGGGGGGGGGGGGGGGGGGGGG");
        setHasilVis(false);
        setLoadingVis(true);
        setLoadingSpinner(false);
      } else if (statusMapping === "") {
        setHasilVis(true);
        setLoadingVis(false);
      }
    }, [statusMapping]);

    // SET SHOW TABLE DARI REQUEST
    const fetchDataReq = async () => {
      const formData = new FormData();
      formData.append("request_token", props.tokenMapping);

      console.log("Detail Mapping : ", props.detailMapping);

      // console.log("Token Mapping : ", tokenMapping);
      try {
        if (props.detailMapping.includes(".pdf")) {
          console.log("Detail Mapping PDF");
          const res = await axios.post(
            process.env.REACT_APP_API_URL + "/result-mapping-ebook/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
            }
          );
          let status = res.data.status;
          let data = res.data.competency_mapping;

          console.log("Status Req : ", status);
          console.log("Data Req : ", data);

          const newData = data.map((item, index) => ({
            no: index + 1, // Incremental id starting from 1
            kompetensi: item.competency,
            level: item.level,
            score: item.similarity,
          }));

          if (newData.length != 0) {
            setData(newData);
          }
          if (status === "done") {
            console.log("Proses Selesai");
          }
        } else if (props.detailMapping.includes(".pptx")) {
          console.log("Detail Mapping PPTX");
          const res = await axios.post(
            process.env.REACT_APP_API_URL + "/result-mapping-module/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
            }
          );
          let status = res.data.status;
          let data = res.data.competency_mapping;

          console.log("Status Req : ", status);
          console.log("Data Req : ", data);

          const newData = data.map((item, index) => ({
            no: index + 1, // Incremental id starting from 1
            kompetensi: item.competency,
            level: item.level,
            score: item.similarity,
          }));

          if (newData.length != 0) {
            setData(newData);
          }

          if (status === "done") {
            console.log("Proses Selesai");
          }
        }
      } catch (error) {
        console.error("Error saat memanggil API:", error);
      }
    };

    useEffect(() => {
      if (props.tokenMapping.length > 1) {
        setTokenMapping(props.tokenMapping);
      }
    }, [props.tokenMapping]);

    useEffect(() => {
      if (props.tokenMapping === undefined || props.tokenMapping === "") {
        // Nothing
      } else {
        console.log("INI TOKEN REQQQQQQQQQ : " + props.tokenMapping);
        console.log("INI STATUS REQQQQQQQQ : " + props.statusMapping);

        if (props.tokenMapping.length > 5) {
          console.log("ADA TOKEN REQ NIHHH");

          if (props.statusMapping !== "done") {
            setStart(true);
          } else {
            fetchDataReq();
          }
          // setStatusMapping(props.statusMapping);
          setNamaFile(props.detailMapping);

          if (props.statusMapping === "error") {
            alert("STATUS MAPPING ERROR");
            props.resetToken("");
            props.resetStatus("");
            props.resetDetail("");
          } else if (props.statusMapping === "processing") {
            setHasilVis(false);
            setLoadingVis(true);
          } else if (props.statusMapping === "waiting") {
            setHasilVis(false);
            setLoadingVis(true);
          } else if (props.statusMapping === "done") {
            setHasilVis(true);
            setLoadingVis(false);
          }
        } else {
          // Nothing
        }
      }
    }, [props.tokenMapping]);

    ///////////////////////////////////////////////////////////////////////////////

    const handleClickMapping = (e) => {
      e.preventDefault();

      if (files === undefined || files === "") {
        alert("Harap Upload Ebook / Module Terlebih Dahulu");
      } else {
        setHasilVis(false);
        setNamaFile(files.name);

        console.log("Jenis Inputan : ", jenisInputan);

        if (jenisInputan === "Ebook (PDF)") {
          console.log("PDF");
          const formData = new FormData();
          formData.append("file_pdf", files);
          formData.append("id_user", props.user.id_user);

          const callMapping1 = async () => {
            const hasil = await mappingEbook(formData, 5);
            if (hasil === "" || hasil === null) {
              alert("GAGAL MAPPING EBOOK => " + errorMsg);
            }
          };

          callMapping1();
        } else if (jenisInputan === "Modul (PPTX)") {
          {
            console.log("PPTX");
            const formData = new FormData();
            formData.append("file_pptx", files);
            formData.append("id_user", props.user.id_user);

            const callMapping2 = async () => {
              const hasil = await mappingModule(formData, 5);
              if (hasil === "" || hasil === null) {
                alert("GAGAL MAPPING MODULE => " + errorMsg);
              }
            };

            callMapping2();
          }
        }
      }
    };

    const onFileChange = (e) => {
      // props.resetToken("");
      // props.resetStatus("");
      // props.resetDetail("");

      setRemoveStatus(false);

      setFiles(e[0]);
      console.log("Hasil upload PDF : ", e[0]);
    };

    const [logoInput, setLogoInput] = useState("pdf.png");
    const [dropname, setDropName] = useState("PDF");
    const [accFile, setAccFile] = useState(".pdf");

    const [removeStatus, setRemoveStatus] = useState(false);

    const handleJenisChange = (e) => {
      // props.resetToken("");
      // props.resetStatus("");
      // props.resetDetail("");

      setJenisInputan(e.value);
      console.log(e.value);

      setRemoveStatus(true);
      console.log(data);

      if (e.value.includes("Ebook")) {
        setLogoInput("pdf.png");
        setDropName("PDF");
        setAccFile(".pdf");
      } else if (e.value.includes("Modul")) {
        setLogoInput("pptx.png");
        setDropName("PPTX");
        setAccFile(".pptx");
      }
    };

    const handleResetRemove = () => {
      setRemoveStatus(false);

      setData([]);
      setTimeout(() => {
        setData([{ no: 1, kompetensi: "-", level: "-", score: "-" }]);
      }, 0);
    };

    return (
      <div className="fullpage_mapping">
        <div className="content_mapping">
          <div className="file_mapping">
            <div style={{ marginBottom: "20px" }}>
              <span className="span-items_mapping">Jenis Inputan</span>
              <Dropdown
                className="dropDown-jenisInputan"
                options={options_jenisInputan}
                value={jenisInputan}
                onChange={(e) => handleJenisChange(e)}
              />
            </div>

            <span className="span-items_mapping">File</span>
            <span className="span-items_mapping" style={{ color: "red" }}>
              *
            </span>
            <DropFileInput
              onFileChange={(e) => onFileChange(e)}
              textjudul="Mapping"
              width="500"
              height="100"
              widthlogo="25"
              logoName={logoInput}
              dropName={dropname}
              accept={accFile}
              multiple={false}
              removeAll={removeStatus}
              resetRemove={handleResetRemove}
            />

            <div className="btnMapping">
              <button
                className="mappingBut"
                onClick={(e) => handleClickMapping(e)}
              >
                Mapping Ebook
              </button>
            </div>
          </div>

          <div style={{ marginLeft: "30px" }}>
            <span className="span-items_mapping">
              Ranking Scoring Mapping{" "}
              <span style={{ color: "red" }}>{namaFile}</span> ke Dirkom 11 :
            </span>
            <div style={{ marginTop: "20px" }}>
              {/* {spinner && (
                <div className="spinner_req">
                  <Spinner />
                </div>
              )} */}

              {loadingSpinner && (
                <div style={{ display: "grid", justifyContent: "center" }}>
                  <span style={{ fontSize: "15px" }}>
                    Masih Belum Masuk Antrian (Tunggu Upload Selesai)
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      Progress Upload : {prosesUpload}% &nbsp;&nbsp;&nbsp;
                    </span>
                    <Spinner />
                  </div>
                </div>
              )}

              {loadingVis && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner />
                </div>
              )}

              {hasilVis && (
                // <ul style={{ listStyleType: "none", fontSize: "14px" }}>
                //   {data.map((item) => (
                //     <li key={item.nomor}>
                //       {item.nomor}. {item.similarity}
                //     </li>
                //   ))}
                // </ul>
                <div>
                  <table className="table_mapping" border="1">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kompetensi</th>
                        <th>Level</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.no}</td>
                          <td style={{ width: "300px" }}>{item.kompetensi}</td>
                          <td style={{ width: "200px" }}>{item.level}</td>
                          <td style={{ width: "100px" }}>{item.score}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li>
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">Requests</span>
            </Link>
          </li>
          <li>
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li>
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li>
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li>
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="main-mapping">
          <div className="container_mapping">
            <h1 id="judul_mapping">Mapping Dirkom 11</h1>
            <MainPageMapping />
          </div>
        </div>
      </section>

      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default Mapping;
