import React from "react";
import "./Module.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { TfiRuler } from "react-icons/tfi";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import axios from "axios";
import { GrIceCream } from "react-icons/gr";
import Popup from "./PopUp.js";
import logo from "../assets/marshall2.png";
import gifLogo from "../assets/Book.gif";
import Spinner from "./SpinnerDs.js";

const { useState, useEffect } = React;

const Module = (props) => {
  /////////////////////////////////////////////////////////////////////
  // VARIABLE

  // VISION KONTEN, LOADING dan HASIL DONE
  // const [kontenVis, setKontenVis] = useState();
  // const [loadingVis, setLoadingVis] = useState();
  // const [doneVis, setDoneVis] = useState();

  // TOKEN DAN STATUS GENERATE
  // const [statusGenerate, setStatusGenerate] = useState("");
  // const [tokenGenerate, setTokenGenerate] = useState("");

  // Inisiasi Interval 3 detik
  const [start, setStart] = useState(false);

  const [download, setDownload] = useState("");

  // DropDown Optional
  const [valgrup, setValGrup] = useState();
  const [valnama, setValNama] = useState();
  const [vallevel, setValLevel] = useState();

  // Dropdown Kompetensi
  const [all_grup_kompetensi, setAll_grup_kompetensi] = useState([]);
  const [options, setOpt] = useState(["one", "two", "three", "four", "five"]);
  const [options_grupkom, setGrupkom] = useState([]);
  const [options_namakom, setNamakom] = useState([]);

  //const [options_levelkom, setLevelkom] = useState([]);

  // Dropdown Referensi
  const mode_referensi = ["Auto", "Manual"];
  const [valref, setValRef] = useState(mode_referensi[1]);
  const [optionsRef, setOptionsRef] = useState([]);

  // Help Button
  const [showPopup, setShowPopup] = useState(false);
  const [judulContent, setJudulContent] = useState("");
  const [isiContent, setIsiContent] = useState("");

  // Menampung isian Referensi
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Hidden Content
  const [refVis, setRefVis] = useState(true);
  const [manualRefVis, setManualRefVis] = useState(true);

  // Jika Tidak di isi = True, tambah class
  const [styleGrup, setStyleGrup] = useState(false);
  const [styleNama, setStyleNama] = useState(false);
  const [styleLevel, setStyleLevel] = useState(false);

  // TextBox Value
  const [valjudul, setValJudul] = useState("");
  const [valelo, setValElo] = useState("");
  const [valtopik1, setValTopik1] = useState("");
  const [valtopik2, setValTopik2] = useState("");
  const [valtopik3, setValTopik3] = useState("");

  // Loading Spinner
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  // var grupArray = [];
  let addStyleGrup = styleGrup ? " red" : "";
  let addStyleNama = styleNama ? " red" : "";
  let addStyleLevel = styleLevel ? " red" : "";

  const [clickGenerate, setClickGenerate] = useState(false);

  // const options_namakom = [
  //   "Computer Vision",
  //   "Deep Learning",
  //   "Machine Learning",
  //   "Natural Language Processing",
  // ];
  const options_levelkom = [
    "Fundamental",
    "Developing",
    "Intermediate",
    "Advanced",
    "Mastery",
  ];

  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  // FUNCTION

  // SETTING INISIAL VARIABLE (AGAR PAS UBAH PAGE, TAMPILAN TIDAK BERUBAH)
  // useEffect(() => {
  //   setKontenVis(props.kontenVis);
  //   setLoadingVis(props.loadingVis);
  //   setDoneVis(props.doneVis);

  //   setTokenGenerate(props.tokenGenerate);
  // }, []);

  // MENGISI DROPDOWN DI GRUP KOM LIST
  useEffect(() => {
    console.log("Grup Komlist : " + props.grupKomlist);
    // setGrupkom(props.grupKomlist);
    props.getOverflow("hidden");
  }, []);

  // Ambil Status Generate per interval 3 detik (KHUSUS REVIEW BUTTON)
  const fetchData = async () => {
    const formData = new FormData();
    formData.append("request_token", props.token);

    // console.log("Token Mapping : ", tokenMapping);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/result-generate-module/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
        }
      );
      let data = res.data.status;
      console.log(data);
      let hasildownload = res.data.your_result;
      // console.log("INI DOWNLOAD GENERATE : ", hasildownload);
      setDownload(hasildownload);
      
      if (data === "done") {
        props.toggleVisModule("status", data);
        console.log("Proses Selesai");
        setStart(false);
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  useEffect(() => {
    let interval;

    if (start) {
      interval = setInterval(async () => {
        await fetchData(); // Call the fetchData function here
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [start, props.token]);

  // useEffect(() => {
  //   const fetchData = () => {
  //     // console.log("Token DB : ", props.token);
  //     // console.log("Token Module : ", tokenGenerate);
  //     const formData = new FormData();
  //     formData.append("request_token", props.token);

  //     axios({
  //       timeout: 360000,
  //       method: "post",
  //       url: process.env.REACT_APP_API_URL + "/result-generate-module/",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //       .then(function (res) {
  //         let data = res.data.status;
  //         console.log(data);
  //         let hasildownload = res.data.your_result;
  //         console.log("INI DOWNLOAD GENERATE : ", hasildownload);
  //         setDownload(hasildownload);

  //         // console.log("Status Generate : ", data);
  //         // setStatusGenerate(data);
  //         props.toggleVisModule("status", data);
  //         // props.getDataModule("statusGenerate", data);
  //       })
  //       .catch(function (res) {
  //         console.log(res);
  //         return "";
  //       });
  //   };

  //   // Fetch data immediately on mount
  //   fetchData();

  //   if (props.status === "done") {
  //     setClickGenerate(false);
  //   }

  //   // Set up interval to fetch data every 3 seconds
  //   const interval = setInterval(fetchData, 3000);

  //   // Clean up interval on unmount
  //   return () => clearInterval(interval);
  // }, [props.token]);

  // Settings Loading dengan Status
  // useEffect(() => {
  //   console.log("TERPANGGIIIIIIIIIIIIIIIIIIIIIIIIIILLLLLLLLLLLLLLLLLLLL");
  //   if (statusGenerate === "done") {
  //     setLoadingVis(false);
  //     setDoneVis(true);
  //     setKontenVis(false);

  //     // Props (AGAR KETIKA PINDAH PAGE VIS TERSIMPAN)
  //     props.getDataModule("loadingVis", false);
  //     props.getDataModule("doneVis", true);
  //     props.getDataModule("kontenVis", false);
  //   }
  //   // Jika selain "Done"
  //   else if (statusGenerate === "processing") {
  //     setLoadingVis(true);
  //     setDoneVis(false);
  //     setKontenVis(false);

  //     // Props
  //     props.getDataModule("loadingVis", true);
  //     props.getDataModule("doneVis", false);
  //     props.getDataModule("kontenVis", false);
  //   } else if (statusGenerate === "waiting") {
  //     setLoadingVis(true);
  //     setDoneVis(false);
  //     setKontenVis(false);

  //     // Props
  //     props.getDataModule("loadingVis", true);
  //     props.getDataModule("doneVis", false);
  //     props.getDataModule("kontenVis", false);
  //   } else if (statusGenerate === "error") {
  //     setLoadingVis(false);
  //     setDoneVis(false);
  //     setKontenVis(true);

  //     // Props
  //     props.getDataModule("loadingVis", false);
  //     props.getDataModule("doneVis", false);
  //     props.getDataModule("kontenVis", false);

  //     alert("STATUS GENERATE MODULE ERROR");
  //   } else {
  //     setLoadingVis(false);
  //     setDoneVis(false);
  //     setKontenVis(true);

  //     // Props
  //     props.getDataModule("loadingVis", false);
  //     props.getDataModule("doneVis", false);
  //     props.getDataModule("kontenVis", false);
  //   }
  // }, [statusGenerate]);

  useEffect(() => {
    console.log("Settings Loading dengan Status");
    console.log("+" + props.status + "+");
    // console.log("Token Req : ", props.tokenReq);

    if (props.status === "done") {
      console.log("DONEEEEEEEEEEEEEEEEEEEEEE");

      props.toggleVisModule("content", false);
      props.toggleVisModule("loading", false);
      props.toggleVisModule("done", true);

    }
    // Jika selain "Done"
    else if (props.status === "processing") {
      console.log("PROCESINGGGGGGGGGGGGGG");

      props.toggleVisModule("content", false);
      props.toggleVisModule("loading", true);
      props.toggleVisModule("done", false);

    } else if (props.status === "waiting") {
      console.log("WAITINGGGGGGGGGGGGGGGGGGGGGGG");

      props.toggleVisModule("content", false);
      props.toggleVisModule("loading", true);
      props.toggleVisModule("done", false);

    } else if (props.status === "error") {
      console.log("ERRORRRRRRRRRRR");

      props.toggleVisModule("content", true);
      props.toggleVisModule("loading", false);
      props.toggleVisModule("done", false);

      // alert("STATUS GENERATE MODULE ERROR");
    } else if (props.status === "RESET") {
      console.log("INI KOSONGGGGGGGGGGGG");
      props.toggleVisModule("content", true);
      props.toggleVisModule("loading", false);
      props.toggleVisModule("done", false);
    }

    // if (clickGenerate) {

    // }
  }, [props.status]);

  // KETIKA DI REQUEST KLIK SHOW
  // useEffect(() => {
  //   console.log("DARI MODULE GENERATE : SHOW REQUEST TELAH DI KLIK");

  //   if (props.reqShow) {
  //     // props.getDataModule("reqShow", false);
  //   }
  // }, [props.reqShow]);

  // SET SHOW TABLE DARI REQUEST
  const fetchDataReq = async () => {
    const formData = new FormData();
    formData.append("request_token", props.tokenReq);

    // console.log("Token Mapping : ", tokenAudio);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/result-generate-module/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
        }
      );
      
      let status = res.data.status;
      let data = res.data.your_result;

      console.log("Status : ", status);
      console.log("Data : ", data);

      if (data.length != 0) {
        setDownload(data);
      }
      if (status === "done") {
        console.log("Proses Selesai");
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  // SET SHOW TABLE DARI REQUEST
  useEffect(() => {
    if (props.tokenReq === undefined) {
      // Nothing
    } else {
      console.log("INI TOKEN REQQQQQQQQQ : " + props.tokenReq);
      console.log("INI STATUS REQQQQQQQQ : " + props.statusReq);

      if (props.tokenReq.length > 5) {
        props.toggleVisModule("token", props.tokenReq);
        props.toggleVisModule("status", props.statusReq);

        if (props.statusReq !== "done") {
          setStart(true);
        } else {
          fetchDataReq();
        }
      } else {
        // Nothing
      }

      if (props.tokenReq.length > 5) {
        // setStatusGenerate(props.statusReq);
        console.log("ADA TOKEN REQ NIHHH");
        props.toggleVisModule("content", false);
        props.toggleVisModule("loading", false);
        props.toggleVisModule("done", false);

        if (props.statusReq === "done") {

          props.toggleVisModule("content", false);
          props.toggleVisModule("loading", false);
          props.toggleVisModule("done", true);

        } else if (
          props.statusReq === "waiting" ||
          props.statusReq === "processing"
        ) {
          if (props.statusReq === "waiting") {
            // setShowCancel(true);
          } else {
            // setShowCancel(false);
          }

          props.toggleVisModule("content", false);
          props.toggleVisModule("loading", true);
          props.toggleVisModule("done", false);

        } else if (props.statusReq === "error") {

          console.log("ERROOOOOOOOOOOOOOOR");

          props.toggleVisModule("content", true);
          props.toggleVisModule("loading", false);
          props.toggleVisModule("done", false);

          alert("STATUS GENERATE MODULE ERROR");
          props.resetToken("RESET");
          props.resetStatus("RESET");
        } else if (props.statusReq === "RESET") {

          props.toggleVisModule("content", true);
          props.toggleVisModule("loading", false);
          props.toggleVisModule("done", false);

        }
      }
    }

  }, [props.tokenReq]);

  const onClickHelp = (e, hint) => {
    e.preventDefault();
    e.stopPropagation();

    if (hint == "judul") {
      setJudulContent("Hint - Judul Modul");
      setIsiContent(
        "Judul yang di isi oleh user akan menjadi Judul Modul ppt yang akan di generate. Dan judul ini berfungsi sebagai acuan juga untuk pemilihan topik dan subtopik jika elo dan topik tidak di isi."
      );
    } else if (hint == "elo") {
      setJudulContent("Hint - Enable Learning Object");
      setIsiContent(
        "ELO yang di isi oleh user akan menjadi tambahan bahasan yang harus ada di dalam modul yang akan di generate."
      );
    } else if (hint == "topik1") {
      setJudulContent("Hint - Topik 1");
      setIsiContent(
        "Jika di isi akan menjadi Judul Topik dari Konten Pertama. Semisal tidak di isi, topik akan auto generate dari LLM nya. Setiap topik akan bersinggungan dengan Judul Modul (jika di isi)."
      );
    } else if (hint == "topik2") {
      setJudulContent("Hint - Topik 2");
      setIsiContent(
        "Jika di isi akan menjadi Judul Topik dari Konten Kedua. Semisal tidak di isi, topik akan auto generate dari LLM nya. Setiap topik akan bersinggungan dengan Judul Modul (jika di isi)."
      );
    } else if (hint == "topik3") {
      setJudulContent("Hint - Topik 3");
      setIsiContent(
        "Jika di isi akan menjadi Judul Topik dari Konten Ketiga. Semisal tidak di isi, topik akan auto generate dari LLM nya. Setiap topik akan bersinggungan dengan Judul Modul (jika di isi)."
      );
    }

    setShowPopup(!showPopup);
  };

  const getKompetensiByGrupKompetensi = async (
    nama_grup_kompetensi,
    maxAttempts
  ) => {
    let arrayStore = [];
    let attempts = 0;
    const formData = new FormData();
    formData.append("nama_grup_kompetensi", nama_grup_kompetensi);

    while (arrayStore.length === 0 && attempts < maxAttempts) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL +
            "/get-list-kompetensi-by-grup-kompetensi/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (
          response.data.list_kompetensi &&
          response.data.list_kompetensi.length > 0
        ) {
          let data = response.data.list_kompetensi;

          for (let i = 0; i < data.length; i++) {
            arrayStore.push(data[i][1]);
          }
          console.log("All Nama Kompetensi : " + arrayStore);
          break; // Hentikan loop jika token berhasil diambil
        } else {
          console.log("Data kosong, mencoba lagi...");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      attempts += 1;
    }

    if (arrayStore.length === 0) {
      console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
    }

    return arrayStore;
  };

  const getEbookReferenceByGrup = async (nama_grup_kompetensi, maxAttempts) => {
    let arrayStore = [];
    let attempts = 0;
    const formData = new FormData();
    formData.append("grup_kompetensi", nama_grup_kompetensi);

    while (arrayStore.length === 0 && attempts < maxAttempts) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL +
            "/get-all-ebook-name-by-grup-kompetensi/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.list_ebook && response.data.list_ebook.length > 0) {
          let data = response.data.list_ebook;
          arrayStore = data.map((item, index) => ({
            value: "ref" + (index + 1).toString(),
            label: item[0],
          }));
          attempts = maxAttempts;
        } else {
          console.log("Data kosong, mencoba lagi...");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      attempts += 1;
    }

    if (arrayStore.length === 0) {
      console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
    }

    return arrayStore;
  };

  // function getKompetensiByGrupKompetensi(nama_grup_kompetensi) {
  //   // ===================================================
  //   // Call List Kompetensi By Nama Grup Kompetensi
  //   const formData = new FormData();
  //   formData.append("nama_grup_kompetensi", nama_grup_kompetensi);

  //   axios({
  //     timeout: 360000,
  //     method: "post",
  //     url: process.env.REACT_APP_API_URL+"/get-list-kompetensi-by-grup-kompetensi/",
  //     data: formData,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then(function (res) {
  //       let data = res.data.list_kompetensi;
  //       let arrayData = [];

  //       for (let i = 0; i < data.length; i++) {
  //         arrayData.push(data[i][1]);
  //       }

  //       console.log("Nama Kompetensi : " + arrayData);
  //       setValNama(arrayData[0]);
  //       // setValNama("");
  //       setNamakom(arrayData);
  //       console.log("Opsi nama kompetensi : ", options_namakom.length);
  //       // return grupArray;
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       return "";
  //     });

  //   // ===================================================
  // }

  // function getEbookReferenceByGrup(nama_grup_kompetensi) {
  //   // ===================================================
  //   // Call List Kompetensi By Nama Grup Kompetensi
  //   const formData = new FormData();
  //   formData.append("grup_kompetensi", nama_grup_kompetensi);

  //   axios({
  //     timeout: 360000,
  //     method: "post",
  //     url: process.env.REACT_APP_API_URL+"/get-all-ebook-name-by-grup-kompetensi/",
  //     data: formData,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then(function (res) {
  //       let data = res.data.list_ebook;
  //       var listRef = data.map((item, index) => ({
  //         value: "ref" + (index + 1).toString(),
  //         label: item[0],
  //       }));

  //       //setOptionsRef(listRef);
  //       setOptionsRef([...listRef]);
  //       console.log(optionsRef);

  //       // for (let i = 0; i < data.length; i++) {
  //       //   grupArray.push(data[i][1]);
  //       // }

  //       //setOptionsRef(data);
  //       // setValNama(grupArray[0]);
  //       // setNamakom(grupArray);
  //       // return grupArray;
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       return "";
  //     });

  //   // ===================================================
  // }

  // DROPDOWN CLICK

  const handleDropdown1Click = () => {
    setClassGrup("dropdown_open");
    setClassNama("dropdown_close");
    setClassLevel("dropdown_close");
  };

  const handleDropdown2Click = () => {
    setClassGrup("dropdown_close");
    setClassNama("dropdown_open");
    setClassLevel("dropdown_close");
  };

  const handleDropdown3Click = () => {
    setClassGrup("dropdown_close");
    setClassNama("dropdown_close");
    setClassLevel("dropdown_open");
  };

  const [clickCount, setClickCount] = useState(0);

  const clickTwice = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (clickCount === 2) {
      console.log("Button clicked twice!");
      setClickCount(0); // Reset jumlah klik setelah aksi dilakukan
    }
  }, [clickCount]);

  //////////////////////////////////////////////////////////////////////////

  const handleChangeValGrup = (e) => {
    setValGrup(e.value);
    console.log("Value Grup Kom : ", e.value);

    const getNamaKom = async () => {
      const hasil = await getKompetensiByGrupKompetensi(e.value, 5);
      setValNama(hasil[0]);
      setNamakom(hasil);
    };

    const getEbook = async () => {
      const hasil = await getEbookReferenceByGrup(e.value, 5);
      setSelectedOptions("");
      setOptionsRef([...hasil]);
      console.log("ALL EBOOK : ", optionsRef);
    };

    getNamaKom();
    getEbook();
  };

  const handleChangeDropSearch = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    let tinggi = document.getElementsByClassName("dropdown_search");
  };

  const handleChangeValJudul = (e) => {
    setValJudul(e.target.value);
    // props.getDataModule("judul", e.target.value);
  };

  const handleChangeValElo = (e) => {
    setValElo(e.target.value);
    // props.dataModule.elo(e.target.value);
  };

  const [rowsTopik1, setRowsTopik1] = useState("1");
  const [rowsTopik2, setRowsTopik2] = useState("1");
  const [rowsTopik3, setRowsTopik3] = useState("1");

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      setRowsTopik1("5");
    }
  };

  const handleKeyDownTopik1 = (event) => {
    if (event.key === "Tab") {
      setRowsTopik1("1");
      setRowsTopik2("5");
    }
  };

  const handleKeyDownTopik2 = (event) => {
    if (event.key === "Tab") {
      setRowsTopik2("1");
      setRowsTopik3("5");
    }
  };

  const handleKeyDownTopik3 = (event) => {
    if (event.key === "Tab") {
      setRowsTopik2("1");
      setRowsTopik3("1");
    }
  };

  const handleClickTopik1 = () => {
    const textareaInput1 = document.getElementById("topik1");
    textareaInput1.rows = "5";
    setRowsTopik1("5");

    if (rowsTopik2 === "5") {
      const textareaInput = document.getElementById("topik2");
      textareaInput.rows = "1";
      console.log("5 nihh Topik2");
      setRowsTopik2("1");
    }
    if (rowsTopik3 === "5") {
      console.log("5 nihh Topik3");
      setRowsTopik3("1");
    }

    console.log("Topik1 : ", rowsTopik1);
    console.log("Topik2 : ", rowsTopik2);
    console.log("Topik3 : ", rowsTopik3);
  };

  const handleClickTopik2 = () => {
    const textareaInput = document.getElementById("topik2");
    textareaInput.rows = "5";
    setRowsTopik2("5");

    if (rowsTopik1 === "5") {
      setRowsTopik1("1");
    }
    if (rowsTopik3 === "5") {
      setRowsTopik3("1");
    }

    console.log("Topik1 : ", rowsTopik1);
    console.log("Topik2 : ", rowsTopik2);
    console.log("Topik3 : ", rowsTopik3);
  };

  const handleClickTopik3 = () => {
    setRowsTopik3("5");

    if (rowsTopik1 === "5") {
      setRowsTopik1("1");
    }
    if (rowsTopik2 === "5") {
      setRowsTopik2("1");
    }

    console.log("Topik1 : ", rowsTopik1);
    console.log("Topik2 : ", rowsTopik2);
    console.log("Topik3 : ", rowsTopik3);
  };

  const handleDefaultTopik = () => {
    setRowsTopik1("1");
    setRowsTopik2("1");
    setRowsTopik3("1");
  };

  // const handleBlurTopik2 = () => {
  //   setRowsTopik2("1");
  // };

  // const handleBlurTopik3 = () => {
  //   setRowsTopik3("1");
  // };

  const handleChangeValTopik1 = (e) => {
    setValTopik1(e.target.value);
    // props.dataModule.topik1(e.target.value);
  };

  const handleChangeValTopik2 = (e) => {
    setValTopik2(e.target.value);
    // props.dataModule.topik2(e.target.value);
  };

  const handleChangeValTopik3 = (e) => {
    setValTopik3(e.target.value);
    // props.dataModule.topik3(e.target.value);
  };

  // CheckBox Dropdown Search
  const [checked, setChecked] = useState(false);
  const [checkedMulti, setCheckedMulti] = useState(false);
  const handleChangeBox = () => {
    if (checked === false) {
      setChecked(true);
      setCheckedMulti(true);
    } else {
      if (selectedOptions.length > 1) {
        setSelectedOptions(selectedOptions[0]);
      }
      setChecked(false);
      setCheckedMulti(false);
      // selectedOptions.splice(0);
    }
  };

  // Logic Scroll Referensi
  // const [dataSource, setDataSource] = useState(Array.from({ length: 10 }));
  // const [val, setVal] = useState([]);

  // const handleAdd = (e) => {
  //   e.preventDefault();
  //   // const abc = [...val, []];
  //   // setVal(abc);
  // };
  // const handleChangeInputRef = (onChangeValue, i) => {
  //   const inputdata = [...val];

  //   for(let j=0; j <= inputdata.length; j++){
  //     for (let k = 0; k <= options.length; k++) {
  //       if (inputdata[j] === options[k]){
  //         options.splice(k,1);
  //       }
  //     }
  //   }

  //   inputdata[i] = onChangeValue.value;
  //   setVal(inputdata);

  //   console.log("HALOOOOOO : " + inputdata[0]);
  //   console.log("Options Length : " + options.length);
  // };

  // const handleDelete = (e, i) => {
  //   e.preventDefault();
  //   const deleteVal = [...val];
  //   deleteVal.splice(i, 1); // splice = remove item (posisi, banyak yang di hapus)
  //   setVal(deleteVal);
  // };

  // console.log(val, "data-");

  const handleChangeRef = (e) => {
    // Something
    if (e.value === "Manual") {
      setManualRefVis(true);
      setChecked(false);
      setCheckedMulti(false);
      setSelectedOptions([]);
      // props.getDataModule("manualrefVis", true);
      // props.getDataModule("textRef", mode_referensi[1]);
      // setVal([""]);
    } else {
      setManualRefVis(false);
      // props.getDataModule("manualrefVis", false);
      // props.getDataModule("textRef", mode_referensi[0]);
      // const deleteVal = [...val];
      // deleteVal.splice(0);
      // setVal(deleteVal);
    }
  };

  const [errorMsg, setErrorMsg] = useState("");

  const generateModule = async (formData, maxAttempts) => {
    let statusStore = "";
    let attempts = 0;

    while (attempts < maxAttempts) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/new-generate-module/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 36000,
          }
        );

        if (
          response.data.your_request_token &&
          response.data.your_request_token.length > 0
        ) {
          statusStore = response.data.your_request_token;
          // console.log("TOKEN GENERATE NYA : ", statusStore);
          break; // Hentikan loop jika token berhasil diambil
        } else {
          console.log("Gagal Generate, mencoba lagi...");
        }
      } catch (error) {
        console.error("Error message:", error.message);
        if (error.response) {
          console.error("Server responded with:", error.response.status);
          setErrorMsg("Server responded with:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
          setErrorMsg("No response received:", error.request);
        } else {
          console.error("Axios config error:", error.message);
          setErrorMsg("Axios config error:", error.message);
        }
      }

      attempts += 1;

      if (statusStore === "") {
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Tunggu 5 detik sebelum mencoba lagi
      }
    }

    if (statusStore === "") {
      console.log(`Gagal Generate Module setelah ${maxAttempts} percobaan.`);
    }

    return statusStore;
  };

  const handleClickGenerate = (e) => {
    // Something
    e.preventDefault();

    // setClickGenerate(true);

    //Log message main content
    console.log("Grup : " + valgrup);
    console.log("Nama : " + valnama);
    console.log("Level : " + vallevel);

    //Log Optional Content
    console.log("Judul : " + valjudul);
    console.log("ELO : " + valelo);
    console.log("Topik1 : " + valtopik1);
    console.log("Topik2 : " + valtopik2);
    console.log("Topik3 : " + valtopik3);

    console.log("Username : " + props.username);

    // Log message referensi
    console.log(selectedOptions);
    console.log(selectedOptions.length);

    if (valgrup === undefined) {
      setStyleGrup(true);
    } else {
      setStyleGrup(false);
    }

    if (valnama === undefined) {
      setStyleNama(true);
    } else {
      setStyleNama(false);
    }

    if (vallevel === undefined) {
      setStyleLevel(true);
    } else {
      setStyleLevel(false);
    }

    // INTEGRASI KE BACKEND
    if (!styleGrup && !styleNama && !styleLevel) {
      const formData = new FormData();
      formData.append("nama_kompetensi", valnama);
      formData.append("level", vallevel);
      formData.append("judul", valjudul);
      formData.append("elo", valelo);
      formData.append("topik_1", valtopik1);
      formData.append("topik_2", valtopik2);
      formData.append("topik_3", valtopik3);
      formData.append("id_user", props.userid);

      if (selectedOptions.length == undefined) {
        formData.append("reference_1", selectedOptions.label);
      } else if (selectedOptions.length > 0) {
        selectedOptions.forEach((item, index) => {
          formData.append(`reference_${index + 1}`, item.label);
        });
      } else {
        // NOTHING
      }

      const generate = async () => {
        const hasil = await generateModule(formData, 5);
        if (hasil === "" || hasil === null) {
          alert("GAGAL GENERATE MODULE " + errorMsg);
        } else {
          // setTokenGenerate(hasil);
          // console.log("HASIL CLICK TOKEN : ", hasil);
          setStart(true);
          props.toggleVisModule("token", hasil);

          props.toggleVisModule("content", false);
          props.toggleVisModule("loading", true);
          props.toggleVisModule("done", false);
        }
      };

      generate();

      // axios({
      //   timeout: 360000,
      //   method: "post",
      //   url: process.env.REACT_APP_API_URL + "/new-generate-module/",
      //   data: formData,
      //   headers: { "Content-Type": "multipart/form-data" },
      // })
      //   .then(function (res) {
      //     let data = res.data.your_request_token;
      //     console.log("TOKEN GENERATE NYA : ", data);
      //     setTokenGenerate(data);
      //     props.toggleVisModule("token", data);

      //     // setKontenVis(false);
      //     // setLoadingVis(true);
      //     // setDoneVis(false);

      //     props.toggleVisModule("content", false);
      //     props.toggleVisModule("loading", true);
      //     props.toggleVisModule("done", false);

      //     // PROPS
      //     // props.getDataModule("kontenVis", false);
      //     // props.getDataModule("loadingVis", true);
      //     // props.getDataModule("doneVis", false);

      //     // props.getDataModule("tokenGenerate", data);
      //   })
      //   .catch(function (res) {
      //     console.log(res);
      //     return "";
      //   });
    }
  };

  // useEffect(() => {
  //   console.log("Token Generate dari Dashboard : ", props.tokenGenerate);
  // }, [props.tokenGenerate]);

  const stylesDropSearch = {
    valueContainer: (base) => ({
      ...base,
      maxHeight: 150,
      overflowY: "auto",
    }),
  };

  const clickBaru = (e) => {
    e.preventDefault();

    // Konten Null
    setValGrup(null);
    setValNama(null);
    setValLevel(null);
    setValJudul("");
    setValElo("");
    setValTopik1("");
    setValTopik2("");
    setValTopik3("");
    setOptionsRef([]);
    setSelectedOptions([]);

    console.log("RESET");

    // props.getDataModule("tokenGenerate", "RESET");
    // props.getDataModule("statusGenerate", "RESET");

    props.resetToken("RESET");
    props.resetStatus("RESET");

    props.toggleVisModule("token", "RESET");
    props.toggleVisModule("status", "RESET");

    // setKontenVis(true);
    // setLoadingVis(false);
    // setDoneVis(false);

    props.toggleVisModule("content", true);
    props.toggleVisModule("loading", false);
    props.toggleVisModule("done", false);

    props.getOverflow("hidden");
  };

  const testClick = (e) => {
    e.preventDefault();

    console.log("Konten Vis : ", props.kontenVis);
    console.log("Loading Vis : ", props.loadingVis);
    console.log("Done Vis : ", props.doneVis);
    console.log("Status Generate : ", props.status);
    // console.log("Click Generate : ", clickGenerate);
  };

  const [classGrup, setClassGrup] = useState("");
  const [classNama, setClassNama] = useState("");
  const [classLevel, setClassLevel] = useState("");

  ////////////////////////////////////////////////////////////////////

  return (
    <div
      className="main-container-module"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.kontenVis && (
        <div>
          {/* <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <h1 style={{fontSize:"30px"}}>Sedang Perbaikan..</h1>
          </div> */}
          <h1 className="judulModul">Generate Module</h1>
          <form className="full-content">
            <div className="main-content-module">
              <div className="judul-Content" onClick={testClick}>
                <span className="span-mainContent">Main Content</span>
              </div>

              <span className="span-items">Grup Kompetensi</span>
              <span className="required"> *</span>
              <div onClick={handleDropdown1Click}>
                <Dropdown
                  className={`dropDown-content-grup ${classGrup}${addStyleGrup}`}
                  options={props.grupKomlist}
                  placeholder="Select an option"
                  onChange={(e) => handleChangeValGrup(e)}
                />
              </div>

              <span className="span-items">Nama Kompetensi</span>
              <span className="required"> *</span>
              <div onClick={handleDropdown2Click}>
                <Dropdown
                  className={`dropDown-content-nama ${classNama}${addStyleNama}`}
                  options={options_namakom}
                  placeholder="Select an option"
                  value={valnama}
                  onChange={(e) => setValNama(e.value)}
                />
              </div>

              <span className="span-items">Level Kompetensi</span>
              <span className="required"> *</span>
              <div onClick={handleDropdown3Click}>
                <Dropdown
                  className={`dropDown-content-level ${classLevel}${addStyleLevel}`}
                  options={options_levelkom}
                  placeholder="Select an option"
                  onChange={(e) => setValLevel(e.value)}
                />
              </div>
            </div>

            <div className="optional-content">
              <div className="judul-Content">
                <span className="span-mainContent">Optional Content</span>
              </div>

              <span className="span-items">Judul Modul</span>
              <span
                className="simbolSpan"
                onClick={(e) => onClickHelp(e, "judul")}
              >
                ?
              </span>
              <input
                type="text"
                id="inputText_judul"
                className="inputText"
                value={valjudul}
                onChange={handleChangeValJudul}
                onClick={handleDefaultTopik}
              ></input>

              <span
                className="hintOptional"
                onClick={(e) => onClickHelp(e, "judul")}
              >
                Judul yang diinputkan akan di set menjadi Judul Konten
              </span>
              <Popup
                handleClose={onClickHelp}
                show={showPopup}
                judulContent={judulContent}
                isiContent={isiContent}
              />

              <span className="span-items">ELO</span>
              <span
                className="simbolSpan"
                onClick={(e) => onClickHelp(e, "elo")}
              >
                ?
              </span>
              <input
                type="text"
                id="inputText_elo"
                className="inputText"
                value={valelo}
                onChange={handleChangeValElo}
                onKeyDown={handleKeyDown}
                onClick={handleDefaultTopik}
              ></input>
              <span
                className="hintOptional"
                onClick={(e) => onClickHelp(e, "elo")}
              >
                ELO yang diinputkan akan jadi acuan pencarian isi konten
              </span>
              <Popup
                handleClose={onClickHelp}
                show={showPopup}
                judulContent={judulContent}
                isiContent={isiContent}
              />

              <span className="span-items">Topik 1</span>
              <span
                className="simbolSpan"
                onClick={(e) => onClickHelp(e, "topik1")}
              >
                ?
              </span>
              {/* <input
                type="text"
                id="inputText_topik1"
                className="inputText"
                value={valtopik1}
                onChange={handleChangeValTopik1}
              ></input> */}
              <textarea
                id="topik1"
                className="inputText_topik"
                value={valtopik1}
                onClick={handleClickTopik1}
                onChange={handleChangeValTopik1}
                rows={rowsTopik1} // Mengatur jumlah baris yang terlihat
                cols="50" // Mengatur lebar textarea
                style={{ whiteSpace: "pre-wrap" }} // Membungkus teks ke bawah
                // onBlur={handleBlurTopik1}
                onKeyDown={handleKeyDownTopik1}
              />
              <span
                className="hintOptional"
                onClick={(e) => onClickHelp(e, "topik1")}
              >
                Topik 1 akan menjadi acuan pencarian & judul dari konten pertama
              </span>

              <span className="span-items">Topik 2</span>
              <span
                className="simbolSpan"
                onClick={(e) => onClickHelp(e, "topik2")}
              >
                ?
              </span>
              {/* <input
                type="text"
                id="inputText_topik2"
                className="inputText"
                value={valtopik2}
                onChange={handleChangeValTopik2}
              ></input> */}
              <textarea
                id="topik2"
                className="inputText_topik"
                value={valtopik2}
                onClick={handleClickTopik2}
                onChange={handleChangeValTopik2}
                rows={rowsTopik2} // Mengatur jumlah baris yang terlihat
                cols="50" // Mengatur lebar textarea
                style={{ whiteSpace: "pre-wrap" }} // Membungkus teks ke bawah
                // onBlur={handleBlurTopik2}
                onKeyDown={handleKeyDownTopik2}
              />
              <span
                className="hintOptional"
                onClick={(e) => onClickHelp(e, "topik2")}
              >
                Topik 2 akan menjadi acuan pencarian & judul dari konten kedua
              </span>

              <span className="span-items">Topik 3</span>
              <span
                className="simbolSpan"
                onClick={(e) => onClickHelp(e, "topik3")}
              >
                ?
              </span>
              {/* <input
                type="text"
                id="inputText_topik3"
                className="inputText"
                value={valtopik3}
                onChange={handleChangeValTopik3}
              ></input> */}
              <textarea
                id="topik3"
                className="inputText_topik"
                value={valtopik3}
                onClick={handleClickTopik3}
                onChange={handleChangeValTopik3}
                rows={rowsTopik3} // Mengatur jumlah baris yang terlihat
                cols="50" // Mengatur lebar textarea
                style={{ whiteSpace: "pre-wrap" }} // Membungkus teks ke bawah
                // onBlur={handleBlurTopik3}
                onKeyDown={handleKeyDownTopik3}
              />
              <span
                className="hintOptional"
                onClick={(e) => onClickHelp(e, "topik3")}
              >
                Topik 3 akan menjadi acuan pencarian & judul dari konten ketiga
              </span>
            </div>

            <div className="container-addField">
              <div className="judul-Content">
                <span className="span-mainContent">Reference Content</span>
              </div>

              <div className="ref-content">
                {refVis && (
                  <div onClick={handleDefaultTopik}>
                    <Dropdown
                      className="dropDown-referensi"
                      options={mode_referensi}
                      placeholder="Select an option"
                      value={valref}
                      onChange={handleChangeRef}
                    />
                  </div>
                )}

                {manualRefVis && (
                  <div
                    className="scroll-container"
                    onClick={handleDefaultTopik}
                  >
                    <Select
                      className="dropdown_search"
                      options={optionsRef}
                      value={selectedOptions}
                      onChange={handleChangeDropSearch}
                      isMulti={checkedMulti}
                      maxMenuHeight={200}
                      styles={stylesDropSearch}
                      placeholder={"Pilih Referensi Ebook .."}
                      noOptionsMessage={() => "Ebook Tidak di temukan"}
                    />
                    <div className="div-multiselect">
                      <input
                        className="checkBox_multiselect"
                        type="checkbox"
                        value={checked}
                        onChange={handleChangeBox}
                      />
                      <span className="checkBox_textMulti">Multi Select?</span>
                    </div>
                  </div>
                )}
              </div>

              <button
                className="modulBut"
                onClick={(e) => handleClickGenerate(e)}
              >
                Generate
              </button>
            </div>
          </form>
        </div>
      )}

      {props.loadingVis && (
        <div className="loading-container">
          <div className="logo-spinner" style={{ display: "flex" }}>
            <img
              className="logo-loading"
              src={logo}
              style={{ width: "320px", height: "120px" }}
            ></img>
            <img
              className="gif-loading"
              src={gifLogo}
              style={{ width: "60px", height: "60px", marginLeft: "10px" }}
            ></img>
          </div>
          <p
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
              fontSize: "15px",
              whiteSpace: "pre-line",
            }}
          >
            {props.status === "waiting"
              ? "Tunggu Antrean Generate Module (Waiting)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"
              : "Generate Module Anda sedang di Proses (Processing)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"}
          </p>

          <div className="footer-loading">
            <button className="generateBaru_but" onClick={clickBaru}>
              GENERATE BARU
            </button>
          </div>
        </div>
      )}

      {loadingSpinner && (
        <div className="loadingSpinner_module">
          <Spinner />
        </div>
      )}

      {props.doneVis && (
        <div className="done_container">
          <div className="done_logo" onClick={testClick}>
            <img src={logo} style={{ width: "320px", height: "120px" }}></img>
          </div>

          <span
            className="done_notif"
            style={{ color: "white", backgroundColor: "green" }}
          >
            GENERATE MODULE TELAH SELESAI
          </span>

          <div className="done_button">
            <button className="done_kembaliBut" onClick={clickBaru}>
              GENERATE BARU
            </button>
            <button
              className="done_downloadBut"
              style={{ display: download.length < 5 ? "none" : "flex" }}
            >
              <a
                href={download}
                style={{ textDecoration: "none", color: "white" }}
              >
                DOWNLOAD MODULE
              </a>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Module;
