import React, { useState, useEffect } from 'react';
import logo from "../assets/marshall.png";
import gifLogo from "../assets/Book.gif";


const LoadingPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Mensimulasikan pemuatan data dengan setTimeout
    const loadData = async () => {
      await new Promise(resolve => setTimeout(resolve, 4000)); // Simulasi pemuatan data selama 4 detik
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
  }

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="logo-spinner" style={{display:"flex", marginTop:"220px"}}>
            <img className="logo-loading" src={logo} style={{width:"200px", height:"70px"}}></img>
            <img className='gif-loading' src={gifLogo} style={{width:"60px", height:"60px", marginLeft:"10px"}}></img>
        </div>
        <p style={{marginTop:"20px"}}>Loading...</p>
      </div>
    );
  }
};

export default LoadingPage;
