// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#generateH1 {
  margin-bottom: 10px !important;
  font-size: 25px !important;
  text-align: center !important;
}

.questBut {
  display: flex;
  align-items: center;

  margin-top: 20px;
  margin-left: 850px;
  height: 100hv;
  cursor: pointer;

  background: var(--itdri3);
  color: #ffff;
  padding: 0.8rem 3.5rem;
  border-radius: 10px;
  font-weight: 500;
  transition: 0.3s ease;

  &:hover {
    background: var(--itdri1);
    color: var(--whiteColor);
  }
}

/* Gaya Default */
/* .questDash {
  background-color: aquamarine;
} */

/* Gaya untuk ponsel */
/* @media (max-width: 768px) {
  .questDash {
    background-color: violet;
  }
} */

/* #prompt {
  display: flex;
  font-size: 15px;
  margin-top: 10px;
}

#inputPrompt {
  width: 100%;
  height: 25px;
  padding: 12px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;

  &&:focus{
    border: 3px solid #555;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/pages/Question.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;;EAEnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,eAAe;;EAEf,yBAAyB;EACzB,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;;EAErB;IACE,yBAAyB;IACzB,wBAAwB;EAC1B;AACF;;AAEA,iBAAiB;AACjB;;GAEG;;AAEH,sBAAsB;AACtB;;;;GAIG;;AAEH;;;;;;;;;;;;;;;;;;;;GAoBG","sourcesContent":["#generateH1 {\n  margin-bottom: 10px !important;\n  font-size: 25px !important;\n  text-align: center !important;\n}\n\n.questBut {\n  display: flex;\n  align-items: center;\n\n  margin-top: 20px;\n  margin-left: 850px;\n  height: 100hv;\n  cursor: pointer;\n\n  background: var(--itdri3);\n  color: #ffff;\n  padding: 0.8rem 3.5rem;\n  border-radius: 10px;\n  font-weight: 500;\n  transition: 0.3s ease;\n\n  &:hover {\n    background: var(--itdri1);\n    color: var(--whiteColor);\n  }\n}\n\n/* Gaya Default */\n/* .questDash {\n  background-color: aquamarine;\n} */\n\n/* Gaya untuk ponsel */\n/* @media (max-width: 768px) {\n  .questDash {\n    background-color: violet;\n  }\n} */\n\n/* #prompt {\n  display: flex;\n  font-size: 15px;\n  margin-top: 10px;\n}\n\n#inputPrompt {\n  width: 100%;\n  height: 25px;\n  padding: 12px 10px;\n  margin: 8px 0;\n  box-sizing: border-box;\n  border: 3px solid #ccc;\n  -webkit-transition: 0.5s;\n  transition: 0.5s;\n  outline: none;\n\n  &&:focus{\n    border: 3px solid #555;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
