// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_style {
  padding: 5px 10px 5px 10px;
  border: 1px solid #ddd;

  &:hover {
    background-color: var(--itdri1) !important;
    color: var(--whiteColor) !important;
    cursor: pointer;
  }
}

.input_req {
  padding: 5px;
  border: 1px solid #ddd;
  width: 30px;
  text-align: center;
}

.prevbut_req {
  border: 1px solid #ddd;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  &:hover {
    background: var(--itdri1);
    color: var(--whiteColor);
    cursor: pointer;
  }
}

.prevbut_req.disabled {
  cursor: not-allowed;

  &:hover {
    background-color: initial;
    color: rgb(184, 184, 184);
  }
}

.nextbut_req {
  border: 1px solid #ddd;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background: var(--itdri1);
    color: var(--whiteColor);
    cursor: pointer;
  }
}

.nextbut_req.disabled {
  cursor: not-allowed;

  &:hover {
    background-color: initial;
    color: rgb(184, 184, 184);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/pages/Pagination.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,sBAAsB;;EAEtB;IACE,0CAA0C;IAC1C,mCAAmC;IACnC,eAAe;EACjB;AACF;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,2BAA2B;EAC3B,8BAA8B;;EAE9B;IACE,yBAAyB;IACzB,wBAAwB;IACxB,eAAe;EACjB;AACF;;AAEA;EACE,mBAAmB;;EAEnB;IACE,yBAAyB;IACzB,yBAAyB;EAC3B;AACF;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,4BAA4B;EAC5B,+BAA+B;;EAE/B;IACE,yBAAyB;IACzB,wBAAwB;IACxB,eAAe;EACjB;AACF;;AAEA;EACE,mBAAmB;;EAEnB;IACE,yBAAyB;IACzB,yBAAyB;EAC3B;AACF","sourcesContent":[".pagination_style {\n  padding: 5px 10px 5px 10px;\n  border: 1px solid #ddd;\n\n  &:hover {\n    background-color: var(--itdri1) !important;\n    color: var(--whiteColor) !important;\n    cursor: pointer;\n  }\n}\n\n.input_req {\n  padding: 5px;\n  border: 1px solid #ddd;\n  width: 30px;\n  text-align: center;\n}\n\n.prevbut_req {\n  border: 1px solid #ddd;\n  padding: 5px;\n  border-top-left-radius: 5px;\n  border-bottom-left-radius: 5px;\n\n  &:hover {\n    background: var(--itdri1);\n    color: var(--whiteColor);\n    cursor: pointer;\n  }\n}\n\n.prevbut_req.disabled {\n  cursor: not-allowed;\n\n  &:hover {\n    background-color: initial;\n    color: rgb(184, 184, 184);\n  }\n}\n\n.nextbut_req {\n  border: 1px solid #ddd;\n  padding: 5px;\n  border-top-right-radius: 5px;\n  border-bottom-right-radius: 5px;\n\n  &:hover {\n    background: var(--itdri1);\n    color: var(--whiteColor);\n    cursor: pointer;\n  }\n}\n\n.nextbut_req.disabled {\n  cursor: not-allowed;\n\n  &:hover {\n    background-color: initial;\n    color: rgb(184, 184, 184);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
