// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.loginStatus {
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 12px;
  font-weight: 200;
  width: 230px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;AAEF;EACE,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,YAAY;AACd","sourcesContent":["@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n.loginStatus {\n  background-color: aqua;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  \n  font-size: 12px;\n  font-weight: 200;\n  width: 230px;\n  height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
