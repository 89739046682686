import React, { useState, useEffect, useRef } from "react";
// import DashboardCSS from "./Dashboard.module.css";
import "../Dashboard.css";
import "./Requests.css";
import Spinner from "./SpinnerDs.js";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
import { BsGear, BsQuestionSquare } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineHistory } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrView } from "react-icons/gr";
import { GoHistory } from "react-icons/go";
import { LuBookDown, LuContact2 } from "react-icons/lu";
import { LuFileQuestion } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination.js";
import Popup from "./PopUp.js";
import EditEbook from "./EditEbook.js";
import { MdSpatialAudio } from "react-icons/md";

const Requests = (props) => {
  // Data untuk tabel
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  // Pop Up
  const [helpPopup, setHelpPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const refreshRequest = (e) => {
    e.preventDefault();
    setRefresh(true);
  };

  const showModule = (token, status, type, detail) => {
    if (type === "Review Modul" && status !== "cancelled") {
      props.tokenReview(token);
      props.statusReview(status);
      props.reqShow(true);
      props.typeShow("review");

      navigate("/dashboard");
      //getDownload(token, "review");
    } else if (type === "Generate Modul" && status !== "cancelled") {
      props.tokenGenerate(token);
      props.statusGenerate(status);
      props.reqShow(true);
      props.typeShow("generate");

      navigate("/dashboard");
      //getDownload(token, "generate");
    } else if (type === "Mapping Ebook" && status !== "cancelled") {
      props.tokenMapping(token);
      props.statusMapping(status);
      props.detailMapping(detail.file);
      navigate("/mapping");
    } else if (type === "Mapping Module" && status !== "cancelled") {
      props.tokenMapping(token);
      props.statusMapping(status);
      props.detailMapping(detail.file);
      navigate("/mapping");
    } else if (type === "Ebook to Audio" && status !== "cancelled") {
      props.tokenAudio(token);
      props.statusAudio(status);
      props.detailAudio(detail.file);
      navigate("/audiolearning");
    } else if (type === "Module to Audio" && status !== "cancelled") {
      props.tokenAudio(token);
      props.statusAudio(status);
      props.detailAudio(detail.file);
      navigate("/audiolearning");
    } else if (type === "Generate CoQa" && status !== "cancelled") {
      props.tokenCoqa(token);
      props.statusCoqa(status);
      props.detailCoqa(detail);
      navigate("/coqa");
    } else if (status === "cancelled") {
      alert("Status Cancelled tidak bisa di Show");
    }
  };

  const [judulEbook, setJudulEbook] = useState("");
  const [jenisEbook, setJenisEbook] = useState("");

  const [download, setDownload] = useState("");

  const editEbookClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowPopup(!showPopup);
    setShowEdit(!showEdit);
  };

  const clickDownload = (e) => {
    e.preventDefault();

    window.open(download, "_blank");
  };

  const showEbook = (e, token) => {
    e.preventDefault();
    e.stopPropagation();

    setShowPopup(!showPopup);

    // console.log("INI TOKEN EBOOK : ", token);

    const formData = new FormData();
    formData.append("request_token", token);

    axios({
      timeout: 360000,
      method: "post",
      url: process.env.REACT_APP_API_URL + "/result-vector-ebook/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data.result;
        console.log("Hasil : ", data);

        setJudulEbook(data.judul_ebook);
        setJenisEbook(data.jenis_ebook);
        setDownload(data.link_download);
      })
      .catch(function (res) {
        console.log(res);
        return "";
      });
  };

  ////////////////////////////////////////////////////////////////////////

  // Delay Awal
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSpinner(true);
    const timer = setTimeout(() => {
      setSpinner(false);
    }, 1000); // 1 detik

    // Bersihkan timer jika komponen di-unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // props.tokenReview("RESET");
    // props.statusReview("RESET");

    // props.tokenGenerate("RESET");
    // props.statusGenerate("RESET");

    // props.resetTokenMapping("");
    props.reset();
  }, []);

  const [spinner, setSpinner] = useState(false);
  const [tableReq, setTableReq] = useState(false);

  // SIMPAN DATA STATUS ORANG YANG SEDANG PROCESSING
  const [prosesUser, setProsesUser] = useState();

  // Count Request
  const [countReq, setCountReq] = useState(0);

  useEffect(() => {
    // Ambil Count Request
    axios({
      timeout: 360000,
      method: "post",
      url: process.env.REACT_APP_API_URL + "/count-request/",
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data.num_request;
        console.log("Count Req : ", data);

        setCountReq(data);

        console.log("Ambil Table Request", currentPage);

        const formData = new FormData();
        formData.append("n_row", 10);
        formData.append("page", currentPage);

        let arrayStore = [];
        let attempts = 0;
        let maxAttempts = 5;

        const getListEbook = async () => {
          while (arrayStore.length === 0 && attempts < maxAttempts) {
            try {
              const response = await axios.post(
                process.env.REACT_APP_API_URL + "/list-request/",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              if (
                response.data.list_request &&
                response.data.list_request.length > 0
              ) {
                let data = response.data.list_request;
                arrayStore = data;

                const newData = data.map((item, index) => ({
                  id: index + ((currentPage - 1) * 10 + 1), // Incremental id starting from 1
                  detail: item.detail,
                  strdetail: JSON.stringify(item.detail),
                  request_token: item.request_token,
                  request_date: item.request_date,
                  request_status: item.request_status,
                  request_type: item.request_type,
                  user: item.user,
                }));

                console.log("Test", newData);
                if (newData.length < 1) {
                  console.log("Refresh");
                  setRefresh(true);
                }

                // Filter array to include only users with user = props.user.nama
                if (
                  props.user.nama === "Telkom Corpu" ||
                  props.user.nama === "Agung S" ||
                  props.user.nama === "Yosef G" ||
                  props.user.nama === "Adi K" ||
                  props.user.nama === "Jordan" ||
                  props.user.nama === "Fadhil" ||
                  props.user.nama === "Tito N" ||
                  props.user.nama === "Gery P" ||
                  props.user.nama === "andri"
                ) {
                  setStatusKlikKanan(true);
                  const updatedObjData = newData.map((obj) => {
                    if (obj.request_type === "review module") {
                      return { ...obj, request_type: "Review Modul" };
                    } else if (obj.request_type === "generate module") {
                      return { ...obj, request_type: "Generate Modul" };
                    } else if (obj.request_type === "extract ebook") {
                      return { ...obj, request_type: "Extract Ebook" };
                    } else if (obj.request_type === "mapping ebook") {
                      return { ...obj, request_type: "Mapping Ebook" };
                    } else if (obj.request_type === "mapping module") {
                      return { ...obj, request_type: "Mapping Module" };
                    } else if (obj.request_type === "module to audio") {
                      return { ...obj, request_type: "Module to Audio" };
                    } else if (obj.request_type === "ebook to audio") {
                      return { ...obj, request_type: "Ebook to Audio" };
                    } else if (obj.request_type === "generate coqa") {
                      return { ...obj, request_type: "Generate CoQa" };
                    }
                    return obj;
                  });

                  setDataTable(updatedObjData);
                } else {
                  setStatusKlikKanan(false);
                  const filterData = newData
                    .filter((item) => item.user === props.user.nama)
                    .map((item, index) => ({ ...item, id: index + 1 }));

                  const updatedObjData = filterData.map((obj) => {
                    if (obj.request_type === "review module") {
                      return { ...obj, request_type: "Review Modul" };
                    } else if (obj.request_type === "generate module") {
                      return { ...obj, request_type: "Generate Modul" };
                    } else if (obj.request_type === "extract ebook") {
                      return { ...obj, request_type: "Extract Ebook" };
                    } else if (obj.request_type === "mapping ebook") {
                      return { ...obj, request_type: "Mapping Ebook" };
                    } else if (obj.request_type === "mapping module") {
                      return { ...obj, request_type: "Mapping Module" };
                    } else if (obj.request_type === "module to audio") {
                      return { ...obj, request_type: "Module to Audio" };
                    } else if (obj.request_type === "ebook to audio") {
                      return { ...obj, request_type: "Ebook to Audio" };
                    } else if (obj.request_type === "generate coqa") {
                      return { ...obj, request_type: "Generate CoQa" };
                    }
                    return obj;
                  });

                  setDataTable(updatedObjData);
                }

                console.log(data);

                setSpinner(true);
                setTableReq(false);

                setTimeout(() => {
                  setSpinner(false);
                  setTableReq(true);

                  const filterProses = newData
                    .filter((item) => item.request_status === "processing")
                    .map((item, index) => ({ ...item, id: index + 1 }));

                  const hasil = filterProses.map((obj) => {
                    return obj;
                  });

                  console.log(hasil);

                  if (hasil.length > 0) {
                    setProsesUser(hasil[0].user);
                  } else {
                    setProsesUser("");
                  }
                }, 500);

                break; // Hentikan loop jika token berhasil diambil
              } else {
                console.log("Data kosong, mencoba lagi...");
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }

            attempts += 1;
          }

          if (arrayStore.length === 0) {
            console.log(
              `Gagal mendapatkan data setelah ${maxAttempts} percobaan.`
            );
          }
        };

        getListEbook();
      })
      .catch(function (res) {
        console.log(res);
        return "";
      });
  }, [refresh]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    console.log("JALANNNNNNNNN");
    const formData = new FormData();
    formData.append("n_row", 10);
    formData.append("page", currentPage);

    axios({
      timeout: 360000,
      method: "post",
      url: process.env.REACT_APP_API_URL + "/list-request/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data.list_request;

        const newData = data.map((item, index) => ({
          id: index + ((currentPage - 1) * 10 + 1), // Incremental id starting from 1
          detail: item.detail,
          strdetail: JSON.stringify(item.detail),
          request_token: item.request_token,
          request_date: item.request_date,
          request_status: item.request_status,
          request_type: item.request_type,
          user: item.user,
        }));

        console.log("Test", newData);
        if (newData.length < 1) {
          console.log("Refresh");
          setRefresh(true);
        }

        // Filter array to include only users with user = props.user.nama
        if (
          props.user.nama === "Telkom Corpu" ||
          props.user.nama === "Agung S" ||
          props.user.nama === "Yosef G" ||
          props.user.nama === "Adi K" ||
          props.user.nama === "Jordan" ||
          props.user.nama === "Fadhil" ||
          props.user.nama === "Tito N" ||
          props.user.nama === "Gery P" ||
          props.user.nama === "andri"
        ) {
          setStatusKlikKanan(true);
          const updatedObjData = newData.map((obj) => {
            if (obj.request_type === "review module") {
              return { ...obj, request_type: "Review Modul" };
            } else if (obj.request_type === "generate module") {
              return { ...obj, request_type: "Generate Modul" };
            } else if (obj.request_type === "extract ebook") {
              return { ...obj, request_type: "Extract Ebook" };
            } else if (obj.request_type === "mapping ebook") {
              return { ...obj, request_type: "Mapping Ebook" };
            } else if (obj.request_type === "mapping module") {
              return { ...obj, request_type: "Mapping Module" };
            } else if (obj.request_type === "module to audio") {
              return { ...obj, request_type: "Module to Audio" };
            } else if (obj.request_type === "ebook to audio") {
              return { ...obj, request_type: "Ebook to Audio" };
            } else if (obj.request_type === "generate coqa") {
              return { ...obj, request_type: "Generate CoQa" };
            }
            return obj;
          });

          setDataTable(updatedObjData);
        } else {
          setStatusKlikKanan(false);
          const filterData = newData
            .filter((item) => item.user === props.user.nama)
            .map((item, index) => ({ ...item, id: index + 1 }));

          const updatedObjData = filterData.map((obj) => {
            if (obj.request_type === "review module") {
              return { ...obj, request_type: "Review Modul" };
            } else if (obj.request_type === "generate module") {
              return { ...obj, request_type: "Generate Modul" };
            } else if (obj.request_type === "extract ebook") {
              return { ...obj, request_type: "Extract Ebook" };
            } else if (obj.request_type === "mapping ebook") {
              return { ...obj, request_type: "Mapping Ebook" };
            } else if (obj.request_type === "mapping module") {
              return { ...obj, request_type: "Mapping Module" };
            } else if (obj.request_type === "module to audio") {
              return { ...obj, request_type: "Module to Audio" };
            } else if (obj.request_type === "ebook to audio") {
              return { ...obj, request_type: "Ebook to Audio" };
            } else if (obj.request_type === "generate coqa") {
              return { ...obj, request_type: "Generate CoQa" };
            }
            return obj;
          });

          setDataTable(updatedObjData);
        }

        console.log(data);
      })
      .catch(function (res) {
        console.log(res);
        return "";
      });
  }, [currentPage]);

  //////////////////////////////////////////////////////////////////////////////
  // KLIK KANAN LOGIC

  const [contextMenu, setContextMenu] = useState(null);
  const contextMenuRef = useRef();
  const [statusKlikKanan, setStatusKlikKanan] = useState(false);

  const handleRightClick = (e, row) => {
    e.preventDefault();
    setCurrentPage(currentPage);
    console.log(row.user);
    setContextMenu({
      x: e.clientX - 65,
      y: e.clientY - 5,
      token: row.request_token,
      status: row.request_status,
      type: row.request_type,
      user: row.user,
    });
  };

  const handleClickOutside = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setContextMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const klikKananShow = (e) => {
    e.preventDefault();
    // console.log(contextMenu.status);
    if (contextMenu.status === "cancelled") {
      alert("Status Cancelled tidak bisa di Show");
    } else {
      showModule(contextMenu.token, contextMenu.status, contextMenu.type);
    }
    setContextMenu(null);
  };

  const klikKananCancel = (e) => {
    e.preventDefault();
    // console.log(contextMenu.status);

    if (contextMenu.status === "waiting") {
      const formData = new FormData();
      formData.append("request_token", contextMenu.token);

      axios({
        timeout: 360000,
        method: "post",
        url: process.env.REACT_APP_API_URL + "/cancel-request/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          let hasil = res.data.status;
          alert("Status Cancel : " + hasil);

          setTimeout(() => {
            setRefresh(true);
          }, 1000);
        })
        .catch(function (res) {
          console.log(res);
          return "";
        });
    } else {
      alert("Hanya Proses Waiting yang bisa di Cancel");
    }

    setContextMenu(null);
  };

  // Help Popup
  const [helpDetail, setHelpDetail] = useState("");
  const [helpType, setHelpType] = useState("");

  const onClickHelp = (e, detail, type) => {
    e.preventDefault();

    if (type === "Review Modul") {
      const newData = { filename: detail.filename };
      setHelpDetail(newData);
    } else if (type === "Generate Modul") {
      const newData = {
        kompetensi: detail.filename,
        judul_modul: detail.judul_modul,
        elo: detail.elo,
        topik_1: detail.topik_1,
        topik_2: detail.topik_2,
        topik_3: detail.topik_3,
        reference_1: detail.reference_1,
        reference_2: detail.reference_2,
        reference_3: detail.reference_3,
        reference_4: detail.reference_4,
        reference_5: detail.reference_5,
      };
      setHelpDetail(newData);
    } else if (type === "Extract Ebook") {
      const newData = {
        judul_ebook: detail.judul_ebook,
        jenis_ebook: detail.jenis_ebook,
        author: detail.author,
        bahasa: detail.bahasa,
        penerbit: detail.penerbit,
        tahun_terbit: detail.tahun_terbit,
        isbn: detail.isbn,
        doi: detail.doi,
        filepath: detail.filepath.substring(
          detail.filepath.lastIndexOf("/") + 1
        ),
      };
      setHelpDetail(newData);
    } else if (type === "Mapping Ebook") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Mapping Module") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Ebook to Audio") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Module to Audio") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Generate CoQa") {
      const newData = {
        jumlah_soal: detail.jumlah_soal,
        level: detail.level.charAt(0).toUpperCase() + detail.level.slice(1).toLowerCase(),
        kompetensi: detail.nama_kompetensi,
        reference: detail.reference,
      };
      setHelpDetail(newData);
    }

    setHelpType(type);

    setHelpPopup(!helpPopup);
  };

  /////////////////////////////////////////////////////////////////////////////

  const TableWithPagination = () => {
    // Data yang akan ditampilkan di halaman saat ini
    // const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Hitung jumlah total halaman
    const totalPages = Math.ceil(countReq / itemsPerPage);
    // const totalPages = Math.ceil(countReq / itemsPerPage);
    const [banyakPages, setBanyakPages] = useState([]);

    // const currentData = dataTable.slice(
    //   (currentPage - 1) * itemsPerPage,
    //   currentPage * itemsPerPage
    // );

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    return (
      <div>
        {spinner && (
          <div className="spinner_req">
            <Spinner />
          </div>
        )}

        {tableReq && (
          <div>
            {!prosesUser ? null : (
              <div style={{ marginBottom: "10px", fontSize: "14px" }}>
                <span style={{ color: "red" }}>[ {prosesUser} ]</span>
                <span>
                  &nbsp;sedang melakukan Proses, harap tunggu antrean..
                </span>
              </div>
            )}
            <table className="table_requests" border="1">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Request Date</th>
                  <th>Type</th>
                  <th>Details</th>
                  <th>Status</th>
                  <th>User</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((item) => (
                  <tr
                    key={item.id}
                    onContextMenu={(e) => handleRightClick(e, item)}
                  >
                    <td>{item.id}</td>
                    <td style={{ width: "300px" }}>{item.request_date}</td>
                    <td style={{ width: "300px", textAlign: "center" }}>
                      {
                        <div>
                          <span>{item.request_type}</span>
                        </div>
                      }
                    </td>
                    <td
                      style={{
                        width: "300px",
                      }}
                      onClick={(e) =>
                        onClickHelp(e, item.detail, item.request_type)
                      }
                    >
                      {
                        <div className="helpDetail_content">
                          {item.request_type === "Review Modul" ? (
                            <div>
                              {item.detail.filename.length > 40 ? (
                                <div>
                                  {item.detail.filename.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.filename}</div>
                              )}
                            </div>
                          ) : item.request_type === "Generate Modul" ? (
                            <div>
                              {item.detail.filename.length > 40 ? (
                                <div>
                                  {item.detail.filename.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.filename}</div>
                              )}
                            </div>
                          ) : item.request_type === "Extract Ebook" ? (
                            <div>
                              {item.detail.judul_ebook.length > 40 ? (
                                <div>
                                  {item.detail.judul_ebook.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.judul_ebook}</div>
                              )}
                            </div>
                          ) : item.request_type === "Mapping Ebook" ? (
                            <div>
                              {item.detail.file.length > 40 ? (
                                <div>
                                  {item.detail.file.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Mapping Module" ? (
                            <div>
                              {item.detail.file.length > 40 ? (
                                <div>
                                  {item.detail.file.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Ebook to Audio" ? (
                            <div>
                              {item.detail.file.length > 40 ? (
                                <div>
                                  {item.detail.file.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Module to Audio" ? (
                            <div>
                              {item.detail.file.length > 40 ? (
                                <div>
                                  {item.detail.file.substring(0, 40)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Generate CoQa" ? (
                            <div>
                              {item.detail.nama_kompetensi.length > 40 ? (
                                <div>
                                  {item.detail.nama_kompetensi.substring(0, 40)}{" "}
                                  ...
                                </div>
                              ) : (
                                <div>{item.detail.nama_kompetensi}</div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      }

                      <Popup
                        handleClose={onClickHelp}
                        show={helpPopup}
                        judulContent={<h1>Details {helpType}</h1>}
                        isiContent={
                          <div style={{ marginTop: "10px", fontSize: "14px" }}>
                            <ul
                              style={{ listStyleType: "none", paddingLeft: 0 }}
                            >
                              {Object.entries(helpDetail).map(
                                ([key, value], index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: "flex",
                                      marginBottom: "8px",
                                      textAlign: "start",
                                    }}
                                  >
                                    <span
                                      style={{
                                        minWidth: "120px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key.replace("_", " ")}
                                    </span>
                                    {value === null || value === "" ? (
                                      <span>: -</span>
                                    ) : (
                                      <span>: {value}</span>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        }
                      />
                    </td>
                    <td
                      style={{
                        width: "250px",
                        textAlign: "center",
                        color:
                          item.request_status === "done"
                            ? "green"
                            : item.request_status === "error"
                            ? "red"
                            : item.request_status === "processing"
                            ? "blue"
                            : item.request_status === "waiting"
                            ? "orange"
                            : "black",
                      }}
                    >
                      {item.request_status}
                    </td>
                    <td style={{ width: "300px", textAlign: "center" }}>
                      {item.user}
                    </td>
                    <td>
                      {item.request_type === "Extract Ebook" ? (
                        <div>
                          <button
                            className="showBut-req"
                            onClick={(e) => showEbook(e, item.request_token)}
                          >
                            Show
                          </button>

                          <Popup
                            handleClose={showEbook}
                            show={showPopup}
                            judulContent={"Details Ebook"}
                            isiContent={
                              <div className="popUp_Ebook">
                                <p
                                  style={{
                                    fontSize: "16px",
                                    margin: "6px 0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Judul Buku :
                                </p>
                                <p>{judulEbook}</p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    margin: "6px 0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Jenis Buku :
                                </p>
                                <p>{jenisEbook}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <button
                                    className="edit_Ebook"
                                    onClick={editEbookClick}
                                  >
                                    Edit
                                  </button> */}
                                  <button
                                    className="downloadReq_Ebook"
                                    onClick={clickDownload}
                                  >
                                    Download
                                  </button>
                                </div>
                              </div>
                            }
                          />

                          <EditEbook
                            handleClose={editEbookClick}
                            show={showEdit}
                          />
                        </div>
                      ) : (
                        <div>
                          <button
                            className="showBut-req"
                            onClick={() =>
                              showModule(
                                item.request_token,
                                item.request_status,
                                item.request_type,
                                item.detail
                              )
                            }
                          >
                            Show
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="footer_requests">
              <div className="refresh_req">
                <button className="refreshBut" onClick={refreshRequest}>
                  Refresh Requests
                </button>
              </div>

              <div className="pagination_req">
                <div className="pagination_req">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>

            {statusKlikKanan && (
              <div>
                {contextMenu && (
                  <div
                    ref={contextMenuRef}
                    className="context-menu"
                    style={{
                      top: `${contextMenu.y}px`,
                      left: `${contextMenu.x}px`,
                    }}
                  >
                    <div onClick={(e) => klikKananShow(e)}>Show Process</div>
                    <div onClick={(e) => klikKananCancel(e)}>
                      Cancel Process
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li>
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">Requests</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li>
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li>
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li>
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="container_request">
          <h1 id="judul_request">Requests History</h1>
          {dataTable.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              {spinner ? (
                <Spinner />
              ) : (
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  BELUM ADA REQUEST HISTORY
                </span>
              )}
            </div>
          ) : (
            <TableWithPagination />
          )}
        </div>
      </section>
      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default Requests;
