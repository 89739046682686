import React, { useState, useEffect } from "react";
import profileImage1 from "../assets/me.jpg";
import profileImage2 from "../assets/agung.jpg";
import profileImage3 from "../assets/adhi.jpg";
import logo from "../../../LoginAssets/itdri.png";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./ContactUs.css"; // Import CSS
import logoWA from "../assets/logowa.png";
import logoGmail from "../assets/logogmail.png";
import { MdSpatialAudio } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import { LuBookDown } from "react-icons/lu";
import { LuFileQuestion } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import { LuContact2 } from "react-icons/lu";

const ContactUs = (props) => {
  // RESET ALL TOKEN
  useEffect(() => {
    props.reset();
  }, []);
  
  const ZoomCard = ({ email, subjek, body, nomor, imageSrc, name }) => {
    return (
      <div className="zoom-card">
        <img src={imageSrc} alt="Profile" className="profile-image" />
        <div className="card_content">
          <span className="card_name">{name}</span>

          <div className="card_contact">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={logoWA} className="logo-image" />
              <a
                href={`https://wa.me/${nomor}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>WhatsApp Contact</span>
              </a>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={logoGmail} className="logo-image" />
              <a
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
                  email
                )}&su=${encodeURIComponent(subjek)}&body=${encodeURIComponent(
                  body
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="card_email">Send GMAIL</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li>
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">Requests</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li>
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li>
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li>
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas-logout" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="container_contact">
          <h1 id="judul_contact">Contact Us - Marshall PIC</h1>

          <div className="main_contact">
            <section className="card1">
              <ZoomCard
                name="Yosef Gunawan"
                imageSrc={profileImage1}
                nomor="+6281324577495"
                email="gunawanyosef21@gmail.com"
                subjek="Feedback Marshall - Yosef Gunawan"
                body="Halo, Yosef Gunawan"
              />
            </section>

            <section className="card2">
              <ZoomCard
                name="Agung Suhendar"
                imageSrc={profileImage2}
                nomor="+6282130418091"
                email="suhendaragung20@gmail.com"
                subjek="Feedback Marshall - Agung Suhendar"
                body="Halo, Agung Suhendar"
              />
            </section>

            <section className="card3">
              <ZoomCard
                name="M Adhi Kurniawan"
                imageSrc={profileImage3}
                nomor="+6282376037446"
                email="gunawanyosef21@gmail.com"
                subjek="Feedback Marshall - M Adhi Kurniawan"
                body="Halo, M Adhi Kurniawan"
              />
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
