import React from "react";
import "./PopUpContent.css"; // Import CSS file for styling

const PopUpContent = ({handleClose, show, judulContent, isiContent}) => {
  const showHideClassName = show ? "modalContent display-block" : "modalContent display-none";

  const handlePopupClick = (event) => {
    // Mencegah event bubbling ke elemen luar
    event.stopPropagation();
  };

  return (
    <div className={showHideClassName}>
      <section className="modalContent-main">
        <button className="close" onClick={handleClose}>
          x
        </button>
        <div onClick={handlePopupClick}>
          <h2>{judulContent}</h2>
          {isiContent}
        </div>
      </section>
    </div>
  );
};

export default PopUpContent;
